/* eslint-disable jsx-a11y/no-onchange */

import React from 'react';
import styled from '@emotion/styled'
import dimensions from 'styles/dimensions';
import colors from 'styles/colors';
import Caret from "../vectors/caret-down.svg"

const SelectContainer = styled.div`
  position: relative;
  cursor: pointer;
  
  svg {
    display: block;
    position: absolute;
    top: 50%;
    right: 2.4rem;
    width: 1.5rem;
    height: 0.8rem;
    fill: ${colors.yellow500};
    transition: transform 0.08s ease-in-out;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: none;
  }

  select {
    appearance: none;
    border: none;
    background-color: ${colors.teal600};
    color: ${colors.yellow500};
    font-family: "Basis Grotesque", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0 6.4rem 0 3.2rem;
    height: 6.4rem;
    
    &::-ms-expand {
      display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {    
    select {
      font-size: 1.4rem;
    }
  }
  
  @media(min-width: ${dimensions.desktopUp}px) {    
    &.Select--topics,
    &.Select--categories {
      display: none;
    }
  }
`;

const NavSelect = (props) => {
  return (
    <SelectContainer className={props.variant ? `Select--${props.variant}` : ''}>
      <select
        onChange={props.handleSelectChange}
        data-type={props.variant}
        value={props.default || props.value || 'all'}
      >
        <option value='all' >All {props.name}</option>

        {props.options.map((option, i) => {
          if (option.topic) {
            return <option key={`topic-option-${i}`} value={option.topic.document.uid}>{option.topic.document.data.title}</option>
          } else if (option.type) {
            return <option key={`topic-option-${i}`} value={option.type.document.uid}>{option.type.document.data.title}</option>
          } else if (option.category) {
            return <option key={`topic-option-${i}`} value={option.category.document.uid}>{option.category.document.data.title}</option>
          }

          return null;
        })}
      </select>

      <Caret/>
    </SelectContainer>
  );
};

export default NavSelect;
